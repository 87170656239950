import { useRetentionHomePageData } from '@finn/auto-ui/modules/home/useRetentionHomePageData';
import { IFeature, useFeatureOverrides } from '@finn/ua-featureflags';
import {
  config,
  FakeDefaultLocale,
  getLocaleFromContext,
  Locale,
} from '@finn/ui-utils';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import React from 'react';

import allFeatures from '~/localization/features2.json';
import PageBodyBuilder from '~/ui-modules/PageBodyBuilder';
import {
  getLandingPageGetServerSideProps,
  LandingPageProps,
} from '~/utils/landingPage';
import { addSentryRenderPageBreadcrumb } from '~/utils/sentry';

interface IProps extends LandingPageProps {
  experiment?: string;
  variant?: string;
}

const Preview: NextPage<IProps> = ({
  pageData,
  reviews,
  brands,
  slug,
  experiment,
  variant,
}) => {
  addSentryRenderPageBreadcrumb(`/ab/${slug}`, slug.toUpperCase());
  useFeatureOverrides(experiment, variant);
  const { retentionPageData } = useRetentionHomePageData();

  const replacedPageData =
    slug === 'home-new' && retentionPageData ? retentionPageData : pageData;

  return (
    <PageBodyBuilder
      data={replacedPageData}
      reviews={reviews}
      brands={brands}
    />
  );
};

export const getStaticPaths: GetStaticPaths = async (ctx) => {
  const locales = ctx.locales?.length
    ? ctx.locales.filter((locale) => locale !== FakeDefaultLocale)
    : [Locale.GERMAN_GERMANY, Locale.ENGLISH_GERMANY, Locale.ENGLISH_USA];
  const paths: any[] = [];

  locales.forEach((locale) => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const features = Object.values(allFeatures[locale]) as IFeature[];

    features.forEach(({ name, pages, buckets }) => {
      if (!pages) return;
      pages.forEach((page) => {
        buckets.forEach((value) => {
          const slug = [page, name, value];
          paths.push({
            params: { slug },
            locale,
          });
        });
      });
    });
  });

  return {
    paths,
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps<IProps> = async (ctx) => {
  const slug = ctx?.params?.slug as string[];
  if (typeof slug === 'string') {
    return { notFound: true };
  }

  const page = slug[0];
  const experiment = slug[1];
  const variant = slug[2];
  const locale = getLocaleFromContext(ctx);
  const props = await getLandingPageGetServerSideProps(page, locale);

  if (props === null) {
    return { notFound: true };
  }

  return {
    props: { ...props, experiment, variant },
    revalidate: config.REVALIDATION_LONG_TIMEOUT,
  };
};

export default Preview;
